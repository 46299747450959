import React from 'react'
import Layout from '../components/Layout/layout'
import styled from 'styled-components'
import Seo from '../components/Seo/seo'

const Div = styled.div`
padding:6rem;
    h1{
        font-family: "Barlow Condensed";
        margin-bottom: 1.5rem;
        font-size: 3.125rem;
        line-height: 1.5;
        font-weight: 700;
        color: var(--textcolor);
        @media (min-width: 768px) {
            font-size: 3.125rem;
        }
    }
    p{
        color: var(--textcolor);
        font-family: "Manrope", sans-serif;
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5;
    }
`

const About = () => {
    return (
        <Layout>
            <Seo title={'About US'}
                description={'Phonera About Page'}
            />
            <Div>
                <div className="container">
                    <h1>ABOUT US</h1>
                    <p>PHONERA is a one-stop destination for all your tech needs in the world of electronics, games, or the web, with the latest iPhone launch. We are a group of dedicated enthusiasts who are equally crazy and completely in love with the world of gadgets; new updates and upgrades; and sensational news related to everything tech.</p>
                    <p>We offer various comparisons and in-depth reviews of the device, as well as information about its crazy specifications.</p>
                    <p>Similarly, we also believe in sharing our experience with everyone, and hence we are here to dish out only the selected and raw materials that are very valuable to you.</p>
                    <p>Include yourself in our journey to stay updated on all the bees and buzz and always stay ahead of the crowd.</p>
                    <p>Thanks for subscribing.</p>
                    <p><strong>PHONERA</strong></p>
                </div>
            </Div>
        </Layout>
    )
}

export default About